<template>
  <b-row>
    <b-col cols="12">
      <CurserValidation />

      <b-card
          title="Results"
      >
        <b-table
            :fields="fields"
            :items="items"
            responsive
            class="mb-0"
        >

          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- A custom formatted column -->
          <template #cell(name)="data">
            <span class="text-nowrap">{{ data.value.first+' - '+ data.value.last }}</span>
          </template>

          <!-- A custom formatted column -->
          <template #cell(progress)="data">
            <b-progress
                :value="data.value.value"
                max="100"
                height="7px"
                :variant="data.value.variant"
                striped
            />
          </template>

          <template #cell(status)="data">
            <b-badge
                pill
                :variant="data.value.variant"
            >
              {{ data.value.text }}
            </b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(preview)="data">
            <b-button variant="primary">
              Preview
            </b-button>
            {{ data.value.text }}
          </template>
        </b-table>
      </b-card>


    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BCard, BButton, BCardText, BTable, BProgress, BBadge} from 'bootstrap-vue'
import CurserValidation from './CurserValidation'

import Ripple from 'vue-ripple-directive'
import BCardCode from "@core/components/b-card-code/BCardCode";
import {codeDataRendering} from "@/views/table/bs-table/code";

export default {
  data() {
    return {
      fields: [
        'Index',
        { key: 'name', label: 'Name' },
        { key: 'progress', label: 'Progress' },
        { key: 'status', label: 'Status' },
        { key: 'preview', label: 'Preview/Download' },
      ],
      items: [
        {
          name: { first: 'Fitbit', last: 'Activity Tracker' },
          status: { text: 'Done', variant: 'success' },
          progress: { value: 100, variant: 'success' },
          preview: { text: 'pre' },
        },
        {
          name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
          status: { text: 'Processing', variant: 'primary' },
          progress: { value: 60, variant: 'primary' },
          preview: { text: 'pre' },
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
          status: { text: 'Waiting', variant: 'warning' },
          progress: { value: 20, variant: 'warning' },
          preview: { text: 'preview' },
        },
      ],
      codeDataRendering,
    }
  },

  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BTable,
    BCardCode,
    BProgress,
    BBadge,

    CurserValidation
  },
  directives: {
    Ripple,
  }
}
</script>
