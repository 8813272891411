<template>
  <b-card-code
    title="Image Curser"
  >
    <b-alert
        v-if="$can('read', 'ToDo')"
        variant="info"
        show
    >
      <div class="alert-body">
        <span><strong>ToDo:</strong> Put requests with variants 10-100 into stack instead of queue. </span>
      </div>
    </b-alert>
    <b-alert
        v-if="$can('read', 'ToDo')"
        variant="info"
        show
    >
      <div class="alert-body">
        <span><strong>ToDo:</strong> Do gifs work? All allowed types?</span>
      </div>
    </b-alert>

    <b-alert
        v-if="$can('read', 'ToDo')"
        variant="info"
        show
    >
      <div class="alert-body">
        <span><strong>ToDo:</strong> I am very open for your criticism and suggestions - Link contact</span>
      </div>
    </b-alert>

    <b-card-text>
      <span>Takes an image and places random bytes into it to make some cursed shit. </span>
      <br>
      <span>A lower amount of bytes will result in a better result and is less likely to break the image.</span>
      <br>
      <span>You can enter a higher amount of Variants to get multiple results. (Max. 20)</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
                label="Amount"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Amount"
                  rules="required|integer|min_value:1|max_value:4294967295"
              >
                <b-form-input
                    v-model="amountValue"
                    :state="errors.length > 0 ? false : null"
                    id="h-number"
                    type="number"
                    placeholder="Amount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
                label="Variants"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Variants"
                  rules="required|integer|min_value:1|max_value:20"
              >
                <b-form-input
                    v-model="variantsValue"
                    :state="errors.length > 0 ? false : null"
                    id="h-number"
                    type="number"
                    placeholder="Variants"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Image"
            >
              <b-form-file
                  v-model="imageValue"
                  id="img"
                  accept="image/jpeg, image/png, image/gif"
              />
            </b-form-group>
          </b-col>


          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
              @click="send(amountValue, variantsValue, imageValue)"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #code>
      {{ codeCurserValidator }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormFile, BCardText, BAlert,
} from 'bootstrap-vue'
import { required, positive, max_value, min_value, image } from '@validations'
import { codeCurserValidator } from './code'
import Alert from "@/views/components/alert/Alert";

export default {
  components: {
    Alert,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormFile,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      amountValue: 10,
      variantsValue: 1,
      imageValue: '',
      codeSimple,
      required,
      positive,
      max_value,
      min_value,
      image
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // ToDo: confirmation
        }
      })
    },
    async send(amount, variants, file)
    {
      console.log(amount, variants, file.slice())

      // ToDo: Validate inputs

      let apiUrl = "https://localhost:7097/img/curse?amount=" + amount + "&variants=" + variants;

      let formData = new FormData();

      formData.append("file", file);

      const ctrl = new AbortController()    // timeout
      setTimeout(() => ctrl.abort(), 5000);

      try {
        let r = await fetch(apiUrl, {method: "POST", body: formData, signal: ctrl.signal});
        console.log('HTTP response code:', r.status);

        if (r.status === 200) {
          r.json().then(data => {
          console.log(data);

          // Clear results
          const resultList = document.getElementById("result-list");
          if (resultList != null) {
            resultList.remove();
          }

          data.forEach(id => this.listener(id));
          });
        }

      } catch (e)
      {
        console.log(e);
      }
    },
    listener(id) {
      console.log(id)

      const resultCard = document.getElementById("result-card");

      let list = document.getElementById("result-list");

      if (list == null)
      {
        // Recreate the result list
        let NewList = document.createElement("ul");
        NewList.id = "result-list";
        resultCard.append(NewList);
        list = NewList;
      }

      console.log(list);

      const listItem = document.createElement("li")
      const text = document.createTextNode(id);

      listItem.append(text);
      list.appendChild(listItem);
      resultCard.appendChild(list);

      let item = JSON.parse("")

      data.items.append(item)
    }
  },
}
</script>
